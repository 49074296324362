import styled from 'styled-components';

export const Header = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    width: 100%;
    height:70px;
    background-color: #fff;
    padding:0 20px;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
    z-index:99;
    position: relative;
    left:0;
    top:0;
    transition:all 0.5s;
    

span{
    display:flex;
    align-items:center;
    justify-content:center;
    width:40px;
    height:40px;
    border-radius:30px;
    transition:all 0.5s;
    &:hover{
        background-color:rgba(0,0,0,0.06);        
       cursor: pointer;
       /* & svg{
           color:rgba(0,0,0,0.5);  
    } */
    
}   

    }


a{  
    margin-right:10px;
    color:#19202b;
  
    &:hover{
        color:rgba(0,0,0,0.5);  
    }
}
    

`;