import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`



*{
    margin:0;
    box-sizing: border-box;
    padding: 0;

    ::-webkit-scrollbar {
	width: 7px;
	height: 10px;

  
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment  {
	display: none;
  
  
}

::-webkit-scrollbar-track-piece  {
	background-color: #3b3b3b;
	/* -webkit-border-radius: 10px; */
  
}

::-webkit-scrollbar-thumb {
	background-color: #12121a;
	/* -webkit-border-radius: 10px; */
  transition:all 2s;
 
}
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  cursor:pointer;
}

input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: .3;
  pointer-events: all;
}




}
*:focus{
  outline: 0;
}

html, body, #root{
    width:100%;
    height:100%;
}

body{
  background-color: #fff;
}

a{
  text-decoration: none;
}

ul{
  list-style: none;
}

button{
 cursor: pointer; 
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {

  
  transition: background-color 5000s ease-in-out 0s;
} 

`;
