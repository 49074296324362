import React,{useState,useEffect} from 'react';
import { AdminDashboard } from '../../layouts/AdminDashboard';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import HeaderStatsAtrasos from '../../components/Headers/HeaderStatsAtrasos';
import ContentHeader from '../../components/ContentHeader';

export const Dashboard = () => {
    const title = "Dashboard";

    document.title = title; 

    return (
        <>
          {/* <AdminDashboard title="Dashboard"> */}
                        <Box
                            component="main"
                            // sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${300}px)` }, border: "1px solid red" }}
                            sx={{  width: "100%" }}
                        >
                                <ContentHeader title={title} />
                            <HeaderStatsAtrasos/>
                      
{/*                             
                            <Toolbar />
                            <Divider />
                            teste
                            <Divider />
                        <h1>Error 407</h1> */}
                        </Box>
            {/* </AdminDashboard> */}
        </>
    );
}