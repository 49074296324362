import { createTheme } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

import defaultTheme from "./default";

// const overrides = {
//   typography: {
//     button: {

//     },
//     fontFamily: '"Roboto", sans-serif',
//     body1: {
//       fontSize: '1rem',

//       lineHeight: 1.5
//     },
//     body2: {
//       fontSize: '0.875rem',

//       lineHeight: 1.57
//     },
//     subtitle1: {
//       fontSize: '1rem',

//       lineHeight: 1.75
//     },
//     subtitle2: {
//       fontSize: '0.875rem',

//       lineHeight: 1.57
//     },
//     overline: {
//       fontSize: '0.75rem',

//       letterSpacing: '0.5px',
//       lineHeight: 2.5,
//       textTransform: 'uppercase'
//     },
//     caption: {
//       fontSize: '0.75rem',
//       fontWeight: 400,
//       lineHeight: 1.66
//     },
//     h1: {
//       fontWeight: 400,
//       fontSize: '3rem',
//       lineHeight: 1.375
//     },
//     h2: {
//       fontWeight: 400,
//       fontSize: '2rem',
//       lineHeight: 1.167
//     },
//     h3: {
//       fontWeight: 400,
//       fontSize: '1.64rem',
//       lineHeight: 1.375
//     },
//     h4: {
//       fontWeight: 400,
//       fontSize: '1.5rem',
//       lineHeight: 1.375
//     },
//     h5: {

//       fontSize: '1.285rem',
//       lineHeight: 1.375
//     },
//     h6: {

//       fontSize: '1.142rem',
//       lineHeight: 1.375
//     }
//   },
// };

export default {
  default: createTheme({ ...defaultTheme},ptBR),
};