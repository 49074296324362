import React, { useEffect, useState } from 'react';
import { AdminDashboard } from '../../layouts/AdminDashboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { Box, Tabs, Tab, Typography } from '@material-ui/core';
import EnhancedTable from '../../components/Table/EnhancedTable';
import { GetAtrasoAtletico } from '../../Utils/Portal'


export default function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        sx={{ width: "100%"}}
        {...other}
  
      >
        {value === index && (
          <Box >
            {children}
          </Box>
        )}
      </Box>
    );
  }