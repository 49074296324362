import React from "react";
import { makeStyles} from "@material-ui/styles";
import {  useTheme } from "@material-ui/core";
import classNames from "classnames";

// styles
var useStyles = makeStyles(theme => ({
  dotBase: {
    width: 5,
    height: 5,
    backgroundColor: "#B9B9B9",
    borderRadius: "50%",
    transition: "all 0.5s",
  },
  dotLarge: {
    width: 8,
    height: 8,
  },
}));

export default function Dot({ size, color }) {
  var classes = useStyles();
  var theme = useTheme();

  return (
    <div
      className={classNames(classes.dotBase, {
        [classes.dotLarge]: size === "large",
        [classes.dotSmall]: size === "small",
      })}
      style={{
        backgroundColor:
          color && theme.palette[color] && theme.palette[color].main,
      }}
    />
  );
}
