import React from "react";
import {
    Box,
    Button,
    Paper,
    Grow,
    Typography,
    CardActions,
    Avatar,
    Divider
     
} from "@material-ui/core";

import { ArrowRight, Warning } from "@material-ui/icons";



export default function CardStats(props) {

    return (
        <Box component="main" sx={{ display: 'flex', width: "100%", flexWrap: 'wrap', justifyContent: 'space-around' }}>
            <Grow in={true} timeout={250}>
                <Box
                    sx={{
                        width: {
                            xs: "100%",
                            sm: "50%",
                            md: "50%",
                            lg: "25%",
                        }, p: 1
                    }}
                >
                    <Paper
                        sx={{
                            width: "100%"
                        }}
                
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '16px 24px' }}>


                            <Box>
                                <Typography variant="body2" sx={{color:"#6E6E6E"}}>ATRASOS ATLÉTICO</Typography>
                                <Typography variant="h5">{props.totalAtletico}</Typography>
                            </Box>
                            <Box>
                                <Avatar sx={{ bgcolor: '#FF0000' }}><Warning /></Avatar>
                            </Box>
                        </Box>
                        <Divider />
                        <CardActions>
                            <Button>Ver todos <ArrowRight /></Button>
                        </CardActions>
                    </Paper>
                </Box>
            </Grow>
            <Grow in={true} timeout={300}>
            <Box
                sx={{
                    width: {
                        xs: "100%",
                        sm: "50%",
                        md: "50%",
                        lg: "25%",
                    }, p: 1
                }}
            >
                <Paper
                    sx={{
                        width: "100%"
                    }}
     
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '16px 24px' }}>


                        <Box>
                            <Typography variant="body2" sx={{color:"#6E6E6E"}}>ATRASOS CRUZEIRO</Typography>
                            <Typography variant="h5">{props.totalCruzeiro}</Typography>
                        </Box>
                        <Box>
                            <Avatar sx={{ bgcolor: '#FF0000' }}><Warning /></Avatar>
                        </Box>
                    </Box>
                    <Divider />
                    <CardActions>
                        <Button>Ver todos <ArrowRight /></Button>
                    </CardActions>
                </Paper>
            </Box>
            </Grow>
            <Grow in={true} timeout={400}>
            <Box
                sx={{
                    width: {
                        xs: "100%",
                        sm: "50%",
                        md: "50%",
                        lg: "25%",
                    }, p: 1
                }}
            >
                <Paper
                    sx={{
                        width: "100%"
                    }}
                
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '16px 24px' }}>


                        <Box>
                            <Typography variant="body2" sx={{color:"#6E6E6E"}}>ATRASOS CARTÕES</Typography>
                            <Typography variant="h5">{props.totalCliente}</Typography>
                        </Box>
                        <Box>
                            <Avatar sx={{ bgcolor: '#FF0000' }}><Warning /></Avatar>
                        </Box>
                    </Box>
                    <Divider />
                    <CardActions>
                        <Button>Ver todos <ArrowRight /></Button>
                    </CardActions>
                </Paper>
            </Box>
            </Grow>
            <Grow in={true} timeout={450}>
            <Box
                sx={{
                    width: {
                        xs: "100%",
                        sm: "50%",
                        md: "50%",
                        lg: "25%",
                    }, p: 1
                }}
            >
                <Paper
                    sx={{
                        width: "100%"
                    }}

                    color="error"
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '16px 24px' }}>


                        <Box>
                            <Typography variant="body2" sx={{color:"#6E6E6E"}}>ATRASOS CORDÕES</Typography>
                            <Typography variant="h5">{props.totalCordao}</Typography>
                        </Box>
                        <Box>
                            <Avatar sx={{ bgcolor: '#FF0000' }}><Warning /></Avatar>
                        </Box>
                    </Box>
                    <Divider />
                    <CardActions>
                        <Button>Ver todos <ArrowRight /></Button>
                    </CardActions>
                </Paper>
            </Box>
            </Grow>
        </Box>
    );
}
