import React,{useState,useEffect} from 'react';
import CardStats from '../Cards/CardStats';
import { GetTotalAtrasoAtletico } from '../../Utils/Portal';
import { GetTotalAtrasoCruzeiro } from '../../Utils/Cruzeiro';
import { GetTotalAtrasoCliente } from '../../Utils/Sis';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress ,Box} from '@material-ui/core';

import {
    Grow
  } from "@material-ui/core";
export default function HeaderStatsAtrasos()  {
const [isLoading,setIsLoading] = useState(true); 
const [totalAtletico,setTotalAtletico] = useState(0);
const [totalCruzeiro,setTotalCruzeiro] = useState(0);
const [totalCliente,setTotalCliente] = useState(0);
const [totalCordao,setTotalCordao] = useState(0);
    useEffect(() => {
        async function load() {
            const totalAtrasoAtletico =  await GetTotalAtrasoAtletico();
            const totalAtrasoCruzeiro=  await GetTotalAtrasoCruzeiro();
            const totalAtrasoCliente =  await GetTotalAtrasoCliente("cartao");
            const totalAtrasoCordao =  await GetTotalAtrasoCliente("cordao");
            setTotalAtletico(totalAtrasoAtletico);
            setTotalCruzeiro(totalAtrasoCruzeiro);
            setTotalCliente(totalAtrasoCliente);
            setTotalCordao(totalAtrasoCordao);
            setIsLoading(false);
        }
        load();
      }, []);
    return (
        <>
        {isLoading ? 

                <CircularProgress color="inherit" />

         :
         <CardStats totalAtletico={totalAtletico} totalCruzeiro={totalCruzeiro} totalCliente={totalCliente}  totalCordao={totalCordao} />
      }

    
        </>
    );
}