export const Error404 = () =>{
  

    return (
      <>
 
      <div>
        <header>
    <h1>Error 404</h1>
        </header>
      </div>
      </>
    );
  }
  
