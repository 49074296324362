import React, { useState } from "react";
import { BackdropModal } from './styles';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/styles';
import { EditUser, GetUserAll } from '../../Utils/User';
import {
  Box,
  Modal,
  Button,
  Fade,
  Grow,
  Tooltip,
  TextField,
  IconButton,
  CircularProgress,
  InputAdornment,
  Switch,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  FormControl
} from "@material-ui/core";
import { Visibility, VisibilityOff, Save, HighlightOff } from '@material-ui/icons';

import { toast } from 'react-toastify';
const useStyles = makeStyles(theme => ({
  tableRightBorder: {
    border: 1,
    borderColor: 'rgba(224, 224, 224, 1)',
    borderStyle: 'solid',
  },
  boxFlex: {
    //  border:'1px solid blue',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    transition: 'all 1s ease-out',
    ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
      flexDirection: 'column',
      justifyContent: 'center'
    },
  },
  topModal: {
    // border:'1px solid blue',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 3
  },
  boxInput: {
    // border:'1px solid red',
    width: '49%',
    marginTop: '-8px',
    ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
      width: '100%',
    },
  },
  boxInputTotal: {
    //  border:'1px solid red',
    width: '99%',
    marginTop: '10px',

  },
  boxRoles: {
    //  border:'1px solid green',
    width: '100%',
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'start',
    justifyContent: 'space-between',
    ['@media (max-width:1270px)']: {// eslint-disable-line no-useless-computed-key
      justifyContent: 'space-between'
    },
    ['@media (max-width:849px)']: {// eslint-disable-line no-useless-computed-key
      justifyContent: 'space-between'
    }
  },
  formControled: {
    border: '1px solid  rgba(0, 0, 0, 0.23)',
    width: '31%',
    borderRadius: '5px',
    margin: '5px 4px',
    minWidth: '182px',
    ['@media (max-width:1285px)']: {// eslint-disable-line no-useless-computed-key
      margin: '5px 3px'
    },
    ['@media (max-width:1270px)']: {// eslint-disable-line no-useless-computed-key
      width: '49%',
      margin: '5px 0'
    },
    ['@media (max-width:849px)']: {// eslint-disable-line no-useless-computed-key
      width: '100%',
      margin: '5px 0'
    }
  },
  overflow: {
    overflow: 'auto',

  },
}));
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '640px',
  transition: 'all 1s ease-out',
  bgcolor: 'background.paper',
  boxShadow: 3,
  borderRadius: 2,
  pt: 2,
  px: 2,
  pb: 3,
    ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
      minWidth: '300px',
    },

};
export default function ModalEditUser(props) {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordConfirmed, setShowPasswordConfirmed] = useState(false);
  const [loadingPut, setLoadingPut] = useState(false);

  async function handleEditStateUser(id) {
    setLoadingPut(true);
    props.handleOpenDropModal();
    const alterStatus = props.userEditModal.status_id === 1 ? 2 : 1;
    const data = {
      status_id: alterStatus
    };
    const resp = await toast.promise(
      EditUser(id, data),
      {
        pending: 'Editando usuário...',
        success: props.userEditModal.status_id === 2 ? 'Usuário ativado com sucesso!!!' : 'Usuário desativado com sucesso!!!',
        error: 'Erro ao atualizar o usuário'
      }
    )
    props.setUserEditModal(prevState => ({ ...prevState, status_id: resp.data.status_id }));
    // props.setLoadingModal(true);
    const filter = "seach=" + props.seach + (props.filterNivel === 0 ? "" : "&group_id=" + props.filterNivel) + (props.filterStatus === 0 ? "" : "&status_id=" + props.filterStatus);
    const userAll = await GetUserAll(props.rowsPerPage, props.page, filter)
    props.setRows(userAll.data);
    props.setTotalUser(userAll.total_user);
    // props.setLoadingModal(false);
    setLoadingPut(false);
    props.handleCloseDropModal();

  };
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickShowPasswordConfirmed = () => setShowPasswordConfirmed(!showNewPasswordConfirmed);

  const onSubmit = async (values, { setFieldValue }) => {
    setLoadingPut(true);
    const { newpassword: newPassword, confirmationpassword: confirmPassword, ...dataValue } = values;
    var valueData = { ...dataValue }
    if (newPassword !== '' || confirmPassword !== '') {
      if (newPassword === confirmPassword) {
        valueData = { ...dataValue, "newPassword": newPassword, "confirmPassword": confirmPassword }
      }
    }
    const nomeSobreNome = valueData.name;
    const dividirNome = nomeSobreNome.split(" ");

    for (let i = 0; i < dividirNome.length; i++) {
        dividirNome[i] = dividirNome[i][0].toUpperCase() + dividirNome[i].substr(1);
    }
    valueData.name = dividirNome.join(" ");
    await toast.promise(
      EditUser(props.userEditModal.id, valueData),
      {
        pending: 'Atualizando usuário...',
        success: 'Usuário atualizado com sucesso!!!',
        error: {
          render({ data }) {
            setLoadingPut(false);
            return 'Falha ao atualizar usuário!!!'
          }
        }
      }
    )
    const filter = "seach=" + props.seach + (props.ilterNivel === 0 ? "" : "&group_id=" + props.filterNivel) + (props.filterStatus === 0 ? "" : "&status_id=" + props.filterStatus);
    const userAll = await GetUserAll(props.rowsPerPage, props.page, filter);
    props.setRows(userAll.data);
    props.setTotalUser(userAll.total_user);
    setFieldValue("newpassword", "")
    setFieldValue("confirmationpassword", "")
    setLoadingPut(false);
  };
  const classes = useStyles();
  const validationSchema = Yup.object({
    email: Yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    name: Yup
      .string('Enter your nome')
      .required('Nome is required'),
    newpassword: Yup
      .string('Enter your password')
      .min(8, 'Password should be of minimum 8 characters length'),
    confirmationpassword: Yup.string().when('newpassword', (newpassword, field) =>
      newpassword ? field.required('Password is required').oneOf([Yup.ref('newpassword')], 'Passwords must match') : field
    ),
  });

  const formik = useFormik({
    initialValues: {
      email: props.userEditModal.email,
      name: props.userEditModal.name,
      nivel: props.userEditModal.group_id,
      newpassword: '',
      confirmationpassword: '',
      roles: "",
      permissions: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });
  return (
    <>
      <Modal
        keepMounted
        BackdropComponent={BackdropModal}
        open={props.openModal}
        onClose={props.handleCloseModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ overflow: 'auto' }}
      >
        <Fade in={props.openModal}>
          <Box sx={{ ...style }}>
            <Box className={classes.topModal} >
              <FormControlLabel
                control={<Switch
                  onClick={() => handleEditStateUser(props.userEditModal.id)}
                  checked={props.userEditModal.status_id === 1 ? true : false}
                />}
                label={props.userEditModal.status_id === 1 ? 'Ativo' : "Desativado"}
                labelPlacement="end"
              />
              <Tooltip title="Fechar" placement="right" >
                <IconButton
                  aria-label="close modal"
                  onClick={props.handleCloseModal}
                  edge="end"
                >
                  <HighlightOff />
                </IconButton>
              </Tooltip>
            </Box>
            <Box className={classes.boxFlex} >
              <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                <Box className={classes.boxFlex}>
                  <Box className={classes.boxInput}>
                    <Grow in={true} timeout={400}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        value={formik.values.email}
                        disabled={loadingPut || props.userEditModal.status_id === 0}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </Grow>
                  </Box>
                  <Box className={classes.boxInput}>
                    <Grow in={true} timeout={450}>
                      <TextField
                        margin="normal"
                        fullWidth
                        required
                        id="name"
                        label="name"
                        name="name"
                        autoComplete="off"
                        value={formik.values.name}
                        disabled={loadingPut || props.userEditModal.status_id === 0}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        inputProps={{ style: { textTransform: "capitalize" } }}
                      />
                    </Grow>
                  </Box>
                </Box>
                <Box className={classes.boxFlex}>
                  <Box className={classes.boxInput}>
                    <Grow in={true} timeout={500}>
                      <TextField
                        margin="normal"
                        fullWidth
                        value={formik.values.newpassword}
                        disabled={loadingPut || props.userEditModal.status_id === 0}
                        onChange={formik.handleChange}
                        error={formik.touched.newpassword && Boolean(formik.errors.newpassword)}
                        helperText={formik.touched.newpassword && formik.errors.newpassword}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowNewPassword}
                                edge="end"
                              >
                                {showNewPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        name="newpassword"
                        label="Nova senha"
                        type={showNewPassword ? "text" : "password"}
                        id="outlined-adornment-newpassword"
                        autoComplete="newpassword"
                      />
                    </Grow>
                  </Box>
                  <Box className={classes.boxInput}>
                    <Grow in={true} timeout={550}>
                      <TextField
                        margin="normal"
                        fullWidth
                        value={formik.values.confirmationpassword}
                        onChange={formik.handleChange}
                        disabled={loadingPut || props.userEditModal.status_id === 0}
                        error={formik.touched.confirmationpassword && Boolean(formik.errors.confirmationpassword)}
                        helperText={formik.touched.confirmationpassword && formik.errors.confirmationpassword}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPasswordConfirmed}
                                edge="end"
                              >
                                {showNewPasswordConfirmed ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        name="confirmationpassword"
                        label="Confirmar nova senha"
                        type={showNewPasswordConfirmed ? "text" : "password"}
                        id="outlined-adornment-confirmationpassword"
                        autoComplete="confirmationpassword"
                      />
                    </Grow>
                  </Box>
                </Box>
                <Box className={classes.boxFlex}>
                  <Box className={classes.boxInputTotal}>
                    <Grow in={true} timeout={550}>
                      <FormControl fullWidth>
                        <InputLabel id="select-nivel-label">Nível</InputLabel>
                        <Select
                          disabled={loadingPut || props.userEditModal.status_id === 0}
                          labelId="select-nivel"
                          id="select-nivel"
                          value={formik.values.nivel}
                          label="Nível"
                          onChange={formik.handleChange}
                          name="nivel-user-edit"
                        >
                             {props.groups.map(group => (
                            <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                          ))}
 
                        </Select>
                      </FormControl>
                    </Grow>
                  </Box>
                </Box>
                <Box className={classes.boxFlex}>
                  <Box className={classes.boxInputTotal}>
                    <Box sx={{ position: 'relative' }}>
                      <Grow in={true} timeout={700}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 2, mb: 1 }}
                          startIcon={<Save fontSize="small" />}
                          disabled={loadingPut || props.userEditModal.status_id === 0}
                        >
                          Salvar
                        </Button>
                      </Grow>
                      {loadingPut && (
                        <CircularProgress
                          size={20}
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}