import { useState, useEffect } from "react";
import { GetUser, GetUserAll } from '../../Utils/User';
import { GetGroupAll } from '../../Utils/Group';
import ModalEditUser from '../ModalEditUser';
import ModalCreateUser from '../ModalCreateUser';
import FiltroPesquisaUsuario from '../FiltroPesquisaUsuario';
import ContentHeader from '../ContentHeader';
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Grow,
  Tooltip,
  Backdrop,
  CircularProgress,
  Paper,
  Box,
  TablePagination,
  Typography,
  Chip
} from "@material-ui/core";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const columns = [
  {
    id: 'nome',
    label: 'Nome',
    align: 'left',
  },
  {
    id: 'email',
    label: 'Email',
    align: 'left',
  },
  {
    id: 'nivel_id',
    label: 'Nivel',
    align: 'center',
  },
  {
    id: 'status_id',
    label: 'Status',
    align: 'center',
  },
  {
    id: 'actions',
    label: 'Ação',
    align: 'center',
  },
];

function TableUsuarios(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalUser, setTotalUser] = useState(0);
  const [seach, setSeach] = useState("");
  const [filterNivel, setFilterNivel] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDrop, setOpenDrop] = useState(false);
  const [userEdit, setUserEdit] = useState({
    "id": "",
    "email": "",
    "name": "",
    "group_id": "",
    "status_id": "",
    "createdAt": "",
    "updatedAt": ""
  });
  const [groups, setGroups] = useState([{
    "id": "",
    "name": ""
  }]);

  const handleChangeFilter = (event) => {
    if (event.target.name === "nivel") {
      if (event.target.value === "") {
        setFilterNivel("");
      } else {
        setFilterNivel(event.target.value);
      }
    }
    if (event.target.name === "status") {
      if (event.target.value === "") {
        setFilterStatus("");
      } else {
        setFilterStatus(event.target.value);
      }
    }
    if(event.target.name === "seach"){
      setSeach(event.target.value);
    }
  };

  const handleChangePage = (event, newPage) => {
    console.log(event,newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    async function loadUsuarios() {
      const filter = "seach="+seach+(filterNivel === 0 ? "" : "&group_id="+filterNivel)+(filterStatus === 0 ? "" : "&status_id="+filterStatus);
      await GetUserAll(rowsPerPage, page, filter)
        .then((e) => {
          setRows(e.data);
          setTotalUser(e.total_user);
          setLoading(false);
        }).catch(error => {
          console.log(error);
          setLoading(false);
        });
    }
    loadUsuarios();
  }, [page, rowsPerPage, seach,filterNivel,filterStatus]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenDrop = () => {
    setOpenDrop(true);
  };
  const handleCloseDrop = () => {
    setOpenDrop(false);
  };
  const handleCloseCreateModal = () => {
    setOpenCreate(false);
  };
  const handleOpenCreateModal = () => {
    setOpenCreate(true);
  };
  async function handleEditUser(id) {
    handleOpenDrop();
    const user = await GetUser(id);
    const gruop = await GetGroupAll("99", 0);
       setGroups(gruop);   
    setTimeout(function () {
      setUserEdit(user);
      handleCloseDrop();
      handleOpen();
    }, 1000);
  };
  return (
    <>
  
      {loading &&
        <CircularProgress color="inherit" /> 
      }
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 99999 }} open={openDrop} > <CircularProgress  />   </Backdrop>
      {open &&
        <ModalEditUser
          openModal={open}
          handleCloseModal={handleClose}
          handleOpenDropModal={handleOpenDrop}
          handleCloseDropModal={handleCloseDrop}
          setLoadingModal={setLoading}
          loadingModal={loading}
          setRows={setRows}
          userEditModal={userEdit}
          setUserEditModal={setUserEdit}
          filterNivel={filterNivel}
          filterStatus={filterStatus}
          seach={seach}
          rowsPerPage={rowsPerPage}
          page={page}
          setTotalUser={setTotalUser}
          groups={groups}
        />
      }
     {openCreate ? (
     <ModalCreateUser
       openCreate={openCreate}
       handleCloseCreateModal={handleCloseCreateModal}
       filterNivel={filterNivel}
       filterStatus={filterStatus}
       seach={seach}
       rowsPerPage={rowsPerPage}
       page={page}
       setTotalUser={setTotalUser}
       setRows={setRows}
       />) : null}
      {!loading &&
        <Box sx={{ width: "100%" }}>
          <Box sx={{ width: "100%", display:"flex", justifyContent:"end", paddingBottom: 2 }} >
          <Button disableElevation onClick={()=>{handleOpenCreateModal()}} variant="contained">Cadastrar usuário</Button>
        </Box>
          <FiltroPesquisaUsuario
          seach={seach}
            changeFilter={handleChangeFilter}
            filterNivel={filterNivel}
            filterStatus={filterStatus}
          />

          <Grow in={true} timeout={400}>
            <Paper  sx={{ width: "100%", padding: 3, paddingBottom: 1 }}  >
              <TableContainer  >
                <Table aria-label="caption table" size={true ? 'small' : 'medium'}>
                  <TableHead>
                    <TableRow >
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ maxWidth: column.maxWidth }}

                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.length === 0 && (
                      <TableRow>
                         <TableCell colSpan={5} align="left" component="th" scope="row" >
                          Nenhum usuário encontrado
                         </TableCell>
                      </TableRow>)
                    }
                    
                    {rows.map(row => (
                      <TableRow hover key={row.id} >
                        <TableCell align="left" component="th" scope="row" >
                          <Typography
                            color="textPrimary"
                            variant="body1"
                          >
                            {row.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="left"  >{row.email}</TableCell>
                        <TableCell align="center" >{row.group_user.name}</TableCell>
                        <TableCell align="center" >
                          <Chip variant="filled" color={row.status_id === 1 ? "lightSuccess" : "lightError"} label={row.status_id === 1 ? "Ativo" : "Desativado"} />
                        </TableCell>
                        <TableCell align="center" >
                          <Tooltip title="Editar Usuário" placement="left" arrow>
                            <Button onClick={() => { handleEditUser(row.id) }} color="inherit">
                              <FontAwesomeIcon icon={faUserEdit} aria-label="Editar usuário" size="lg" />
                            </Button>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={totalUser}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grow>
        </Box>
      }
    </>
  )
}
export default TableUsuarios;