import * as React from 'react';
import {Box} from '@material-ui/core';
import {LinearProgress} from '@material-ui/core';;


export default function LinearIndeterminate(color) {

    

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  );
}