import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { Warrper,ContentHeader,ContainerContent,PanelRow } from './styles';
import Typography from '@material-ui/core/Typography';
// import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';




function MainContent(props) {
  const location = useLocation();
  // console.log(location.pathname);
  const title = props.title;

  // Inicializamos um booleano no estado do componente para sabermos quando
  // devemos mostrar ou esconder o componente de acordo com o `setTimeout`.
  // const [finishedTimeout, setFinishedTimeout] = React.useState(false);

  // O "manejo" necessário: integraremos o `setTimeout` com o React através do
  // hook `useEffect`:
  // React.useEffect(() => {
  //   const id = setTimeout(() => {
  //     setFinishedTimeout(true);
  //   }, 1000);

  //   return () => clearTimeout(id);
  // }, []);


  return (

    <Warrper>
    
          {/* <Paper sx={{borderRadius:2}}  elevation={0} >             */}
          <ContainerContent>
          <PanelRow>
          {props.children}
          </PanelRow>
          </ContainerContent>         
          {/* </Paper> */}
    </Warrper>

  );
}

export default MainContent;