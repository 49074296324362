import styled from 'styled-components';
import {  Typography }  from '@material-ui/core';

export const Sidebar = styled.div`
    display:block;    
    width: ${props => props.isResponsive ? "70px" : "260px"};
    height:100%;
    background-color: #f6f4f9;


    transition:all 0.5s;
    z-index:2;

  
   
    /* margin-left:${props => props.isResponsive ? "-230px" : "0px"}; */

     
    @media(max-width: 800px) {
    width:260px;
    height:calc(100% - 70px);
    position:absolute;
    top:70px;
    left:${props => props.isResponsive ? "-260px" : "0px"};
      
    }
    &:hover{
      ${props => props.isResponsive && ({ width: '260px' })
   }
    }
`;
export const LogoContainer = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:start;
    align-items:center;
    background-color: rgb(34,45,50);    
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
    height:70px;  
    position:relative;
   
`;
export const Name = styled.h2`
    font-size:20px;  
    color:white;

`;
export const Avatar = styled.img`
  height: 50px;
  margin-top: 10px ;
  transition:all 0.5s;
  padding:7px;


`;
export const LinksContainer = styled.div`  
  height:100%;
  width: 100%;  
  background-color:rgb(34,45,50);
  overflow-y:auto;
  overflow-x: hidden;

  
`;

export const Links = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;  
  height: 60%;
  transition:all 0.5s;
  flex-shrink: 0;
  white-space: 'nowrap';

  
  
`;

export const Link = styled.li`
  background-color:${props => props.active ? "rgb(30,40,44)" : "rgb(34,45,50)"};
  display: flex;
  align-items:center;
  justify-content:start;
  height:44px;
  color: ${props => props.active ? "white" : "#b8c7ce"};
  cursor: ${props => props.active ? "default" : "pointer"};
  width:240px;
  border-left: 3px solid ${props => props.active ? "white" : "transparent"};
  padding:25px 25px;
  transition: all 0.5s;
  font-size:1rem;
  font-weight: 300;
  text-transform: capitalize;
  text-decoration:none;
  position: relative;

  svg {
    margin-right:10px;
     font-size: 1rem;

    
  }
  &:hover{
        background-color: ${props => props.active ? "rgb(30,40,44)" : "#3b4452"};
        color:white;
        border-left-color:white;
        a{
          color:white;
        }
       
    }


`;

export const Title = styled.div`
   opacity: ${props => props.sidebarResponsive ? "0" : "1"};   
   transition: all 0.5s;
   ${Sidebar}:hover & {
    opacity: 1;
  }
`;

export const Submenu = styled.div`


  /* color: ${props => props.active ? "white" : "#b8c7ce"};
  cursor: ${props => props.active ? "default" : "pointer"}; */
  background-color: #2c3b41;
  width:100%;
  display:${props => props.openTeste ? "block" : "none"}; 
  &:hover{
        /* background-color: ${props => props.active ? "#2c3b41" : "#2c3b41"}; */
        color:white;
        border-left-color:white;
        a{
          color:white;
        }
       
    }
`;
export const SubLink = styled.li`
  /* background-color:${props => props.active ? "rgb(30,40,44)" : "rgb(34,45,50)"}; */
  display: flex;
  align-items:center;
  justify-content:start;
  height:35px;
  color: ${props => props.active ? "white" : "#b8c7ce"};
  cursor: ${props => props.active ? "default" : "pointer"};
  width:100%;
  padding:25px 0px 25px 20px;
  transition: all 0.1s;
  text-transform: capitalize;
  text-decoration:none;

  svg {
    margin-right:10px;
     font-size: 1.2rem;

    
  }
  &:hover{
        color:white;
        border-left-color:white;
        a{
          color:white;
        }
       
    }


`;

// export const ContactContainer = styled.div`
//   width: 60%;
//   background-color: #091322;
//   color: #c4c4c4;
//   height: 110px;
//   margin: auto auto;
//   border-radius: 1rem;
//   display: flex;
//   flex-direction: column;
//   padding: 1rem;
//   a {
//     color: white;
//     text-decoration: none;
//   }

// `;