import styled from 'styled-components';

export const ProfileParent = styled.div`
    padding:10px 0;
    width:100%;
    
`;

export const Profile = styled.div`
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
   
        @media(max-width: 800px) {
            flex-direction: column;
        
        }

`;

export const ProfileImg = styled.div`
   
    width:30%;
    max-width:470px;
    min-width:263px;
    display:flex;
    justify-content: center;
    /* align-items:center; */
    height:285px;
    @media(max-width: 800px) {
        width:100%;
        max-width:100%;
        min-width:100%;
 
        
        }
    
`;


export const Img = styled.div`
    width:300px;    
    min-width:200px;
    display:flex;
    justify-content: center;
    align-items:center;
    box-shadow:0 0 2rem rgba(0,0,0,0.2);
    position:relative;
    border-radius:3px;
    img{
        width:100%;
        height:100%;
    }
    @media(max-width:800px) {
    width:250px;
    min-width:250px;
    height:250px;
        
        }
        @media(max-width:600px) {
    width:200px;
    min-width:200px;
    height:200px;
        
        }
`;
export const UploadImg = styled.div`
    width:100%;
    position:absolute;
    bottom:0;
    height:30px;
`
export const ProfileForm = styled.div`
   
    width:70%;
     padding-left:30px;
    @media(max-width: 800px) {
        width:100%;
        padding:0;
        margin-top:20px;
        }


`;

export const UploadImg2 = styled.div`
    width:100%;
    /* padding:5px; */
`;