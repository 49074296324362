import React from "react";
import { AdminDashboard } from '../../layouts/AdminDashboard';
import Box from '@material-ui/core/Box';

import TableUsuarios from '../../components/TableUsuarios';
import ContentHeader from '../../components/ContentHeader';




export const Usuario = () => {
  const title = "Usuários";

  document.title = title; 
  return (
     <>
    {/* // <AdminDashboard  title="Usuários"> */}
      <Box
        component="main"
        // sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${300}px)` }, border: "1px solid red" }}
        sx={{ width: "100%" }}
      >
               <ContentHeader title={title} ></ContentHeader>
        <TableUsuarios />

      </Box>
     {/* </AdminDashboard> */}
    </> 
  );
}

