import { useContext } from 'react';
import { AuthContext } from '../../contexts/auth';
import { Header } from './styles';
import { faArrowLeft, faBars, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  Link }  from '@material-ui/core';


function HeaderContainer(props) {

  const { signOut,responsiveSidebar, sidebarResponsive } = useContext(AuthContext);


    return (
      
    <Header>
 
                    <span onClick={() => responsiveSidebar()} >
                      {sidebarResponsive ? <FontAwesomeIcon  icon={faBars} size="lg" /> : <FontAwesomeIcon  icon={faArrowLeft} size="lg" />}
                   
                    </span>

                    {/* <Button
                      type="submit"
                      sx={{ mt: 1, mb: 1 }}
                      onClick={()=>signOut()}

                    >
                      <FontAwesomeIcon icon={faSignOutAlt} /> Fazer logout
                    </Button> */}
                    
                    <Link href="#" underline="none" onClick={() => signOut()}> <FontAwesomeIcon icon={faSignOutAlt} /> Fazer logout</Link>
      </Header>
    
    
      
    );
  }
  
  export default HeaderContainer;