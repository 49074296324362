import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../contexts/auth';
import { Backdrop, CircularProgress } from '@material-ui/core';
import useWindowWidth from "../Utils/useWindowWidth";
const PrivateRoutes = ({ component: Component, role, ...rest }) => {
    const { signed, loading, userData, responsiveSidebar } = useContext(AuthContext);
    const from = rest.location.pathname;
    const width = useWindowWidth();
    useEffect(() => {
        if(width < 800){
        responsiveSidebar();
    }
    },[from]);


    if (loading) {
        return (
            <div>
                <Backdrop
                    sx={{ background: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }

    if (!signed) { return <Redirect to={{ pathname: '/', state: { from: from } }} /> }
    if (role) {
        const permissionName = userData.group_user.permissions.map((permission) => permission.name);
        const existRoles = permissionName.some((r) => role.includes(r));
        if (!existRoles) {
            return (<Redirect to={{ pathname: '/app/dashboard', state: { from: from } }} />)
        }

        return (
            <Route
                {...rest}
                render={props => (
                    <Component {...props} />
                )}
            />
        )
    }
    return (
        <Route
            {...rest}
            render={props => (
                <Component {...props} />
            )}
        />
    )

}
export default PrivateRoutes;

