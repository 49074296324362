import React, { useEffect, useState } from 'react';
import { AdminDashboard } from '../../layouts/AdminDashboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { Box, Tabs, Tab, CircularProgress } from '@material-ui/core';
import EnhancedTable from '../../components/Table/EnhancedTable';
import TabPanel from '../../components/Tab/TabPanel';
import { GetTotalAtrasoAtletico, GetAtrasoAtletico } from '../../Utils/Portal';
import { GetTotalAtrasoCruzeiro, GetAtrasoCruzeiro } from '../../Utils/Cruzeiro';
import { GetTotalAtrasoCliente, GetAtrasoCliente } from '../../Utils/Sis';
import ContentHeader from '../../components/ContentHeader';


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const ProducaoAtraso = ({ match }) => {
  const title = "Produção";

  document.title = title; 
  const [atletico, setAtletico] = useState();
  const [cruzeiro, setCruzeiro] = useState();
  const [cliente, setCliente] = useState();
  const [cordao, setCordao] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState(0);



  const handleChange = async (event, newValue) => {
    setIsLoading(true);
    setValue(newValue);
    if (newValue === 0) {
      const atrasoAtletico = await GetAtrasoAtletico();
      setAtletico(atrasoAtletico);
    }
    if (newValue === 1) {
      const atrasoCruzeiro = await GetAtrasoCruzeiro();
      setCruzeiro(atrasoCruzeiro);
    }
    if (newValue === 2) {
      const atrasoCliente = await GetAtrasoCliente("cartao");
      setCliente(atrasoCliente);
    }
    if (newValue === 3) {      
      const atrasoCordao = await GetAtrasoCliente("cordao");
      setCordao(atrasoCordao);      
    }
    setIsLoading(false);
  };


  useEffect(() => {
    async function load() {
      setIsLoading(true);
      const atrasoAtletico = await GetAtrasoAtletico();
      setAtletico(atrasoAtletico);

      setIsLoading(false);
    }
    load();
  }, []);

  const headCellsAtletico = [
    {
      id: 'id',
      numeric: true,
      disablePadding: true,
      label: 'remessa',
    },
    {
      id: 'cliente',
      numeric: false,
      disablePadding: true,
      label: 'cliente',
    },
    {
      id: 'creation_date',
      numeric: true,
      disablePadding: true,
      label: 'solicitacao',
    },
    {
      id: 'deadline_date',
      numeric: true,
      disablePadding: true,
      label: 'prazo',
    },
    {
      id: 'cpf_socio',
      numeric: true,
      disablePadding: true,
      label: 'cpf',
    },
    {
      id: 'numero_socio',
      numeric: true,
      disablePadding: true,
      label: 'numero socio',
    },
    {
      id: 'nome_socio',
      numeric: false,
      disablePadding: true,
      label: 'nome socio',
    },
    {
      id: 'modelo',
      numeric: true,
      disablePadding: true,
      label: 'modelo',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: true,
      label: 'status',
    },
  ];
  const headCellsCruzeiro = [
    {
      id: 'id',
      numeric: true,
      disablePadding: true,
      label: 'remessa',
    },
    {
      id: 'cliente',
      numeric: false,
      disablePadding: true,
      label: 'cliente',
    },
    {
      id: 'creation_date',
      numeric: false,
      disablePadding: true,
      label: 'solicitacao',
    },
    {
      id: 'deadline_date',
      numeric: false,
      disablePadding: true,
      label: 'prazo',
    },
    {
      id: 'numero_socio',
      numeric: true,
      disablePadding: true,
      label: 'numero socio',
    },
    {
      id: 'nome_socio',
      numeric: false,
      disablePadding: true,
      label: 'nome socio',
    },
    {
      id: 'modelo',
      numeric: true,
      disablePadding: true,
      label: 'modelo',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: true,
      label: 'status',
    },
  ];
  const headCellsCliente = [
    {
      id: 'id',
      numeric: true,
      disablePadding: true,
      label: 'id',
    },
    {
      id: 'cliente',
      numeric: false,
      disablePadding: true,
      label: 'cliente',
    },
    {
      id: 'creation_date',
      numeric: true,
      disablePadding: true,
      label: 'solicitacao',
    },
    {
      id: 'deadline_date',
      numeric: true,
      disablePadding: true,
      label: 'prazo',
    },
    {
      id: 'remessa_id',
      numeric: true,
      disablePadding: true,
      label: 'remessa',
    },
    {
      id: 'cnpj',
      numeric: true,
      disablePadding: true,
      label: 'cnpj',
    },
    {
      id: 'quantidade',
      numeric: true,
      disablePadding: true,
      label: 'quantidade',
    },
    {
      id: 'produto',
      numeric: false,
      disablePadding: true,
      label: 'produto',
    },
    {
      id: 'status_atual_id',
      numeric: false,
      disablePadding: true,
      label: 'status',
    },
    // {
    //   id: 'sistema_id',
    //   numeric: false,
    //   disablePadding: true,
    //   label: 'sistema',
    // },
  ];
  const headCellsCordao = [
    {
      id: 'id',
      numeric: true,
      disablePadding: true,
      label: 'id',
    },
    {
      id: 'cliente',
      numeric: false,
      disablePadding: true,
      label: 'cliente',
    },
    {
      id: 'creation_date',
      numeric: true,
      disablePadding: true,
      label: 'solicitacao',
    },
    {
      id: 'deadline_date',
      numeric: true,
      disablePadding: true,
      label: 'prazo',
    },
    {
      id: 'cnpj',
      numeric: true,
      disablePadding: true,
      label: 'cnpj',
    },
    {
      id: 'quantidade',
      numeric: true,
      disablePadding: true,
      label: 'quantidade',
    },
    {
      id: 'produto',
      numeric: false,
      disablePadding: true,
      label: 'produto',
    },
    {
      id: 'status_atual_id',
      numeric: false,
      disablePadding: true,
      label: 'status',
    },

  ];
  // console.log(match);
  return (
    <>
{/* 
      <AdminDashboard title="Atraso Produção"> */}

        <Box
          component="main"
          // sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${300}px)` }, border: "1px solid red" }}
          sx={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: "wrap" }}
        >
      <ContentHeader title={title} />
          <Box sx={{ width: "100%" }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs" >
              <Tab label="Atlético" {...a11yProps(0)} />
              <Tab label="Cruzeiro" {...a11yProps(1)} />
              <Tab label="Cartões" {...a11yProps(2)} />
              <Tab label="Cordões/Roller Clips/Protetor" {...a11yProps(3)} />
            </Tabs>
          </Box>
          {isLoading ?

            <CircularProgress color="inherit" />

            :
            <>
              <TabPanel value={value} index={0}>

                <EnhancedTable title="Atlético" dataTable={atletico} dataHead={headCellsAtletico} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <EnhancedTable title="Cruzeiro" dataTable={cruzeiro} dataHead={headCellsCruzeiro} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <EnhancedTable title="Cartão" dataTable={cliente} dataHead={headCellsCliente} />
              </TabPanel>
              <TabPanel value={value} index={3}>
              <EnhancedTable title="Cordao" dataTable={cordao} dataHead={headCellsCordao} />
              </TabPanel>
            </>
          }
        </Box>
      {/* </AdminDashboard> */}
    </>
  );
}