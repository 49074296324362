import React, { useContext} from "react";
import { AuthContext } from '../../contexts/auth';

const PermissionComponent = ({
    role, 
    children
}) => {  
  const {userData } = useContext(AuthContext);
  const permissionName =  userData.group_user.permissions.map((permission) => permission.name);             
  const existRoles =  permissionName.some((r) => role.includes(r)) ; 
  
  return <>{existRoles && children}</>;
};
export default PermissionComponent;