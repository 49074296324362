import api from "../services/api";



export async function GetTotalAtrasoCruzeiro() {
   const totalAtrasoCruzeiro =  await api.get("/total-atraso-cruzeiro")
    .then((e)=>{
        return e.data
    }).catch(error => {
          return error.response.data
      });

    return totalAtrasoCruzeiro
}
export async function GetAtrasoCruzeiro() {
    const AtrasoCruzeiro =  await api.get("/atraso-cruzeiro")
     .then((e)=>{
         return e.data
     }).catch(error => {
           return error.response.data
       });
 
     return AtrasoCruzeiro
 }

export function HelloTester(e) {

    console.log(e.target.dataset.target);
}