import React from "react";
import { WarrperHeader } from './styles';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';

function ContentHeader(props) {
    const location = useLocation();
    // console.log(location.pathname);
    const title = "teste";


    return (

        <WarrperHeader>
            <Typography variant="h3" size="sm" sx={{ color: '#B9B9B9' }}> {props.title}</Typography>
            {<Typography color="text.primary">{location.pathname}</Typography>}
            {/* {props.children} */}
        </WarrperHeader>

    );
}

export default ContentHeader;





