import { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../contexts/auth';
import { Link as RouterLink } from 'react-router-dom';
import { Sidebar, LogoContainer, Avatar, LinksContainer, Links, Link, Title, Submenu, SubLink } from './styles';
import AvatarImage from '../../assets/logo.png';
import Simbol from '../../assets/simbol.png';
import { faHome, faUserAlt, faClipboardCheck, faUsers, faClipboardList, faCalendarTimes, faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import PermissionComponent from "../../components/PermissionComponent";
import { Typography, Box, List } from '@material-ui/core';
import MenuComponent from '../Menu/MenuComponent';

// components
import SidebarLink from "../SideBar/SidebarLink/SidebarLink";
import Dot from "./Dot";

const structure = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <FontAwesomeIcon icon={faHome} /> },
  { id: 1, label: "Meu Perfil", link: "/app/perfil", icon: <FontAwesomeIcon icon={faUserAlt} /> },
  {
    id: 2,
    label: "Usuários",
    link: "/app/usuarios",
    icon: <FontAwesomeIcon icon={faUsers} />,
  },
  {
    id: 3,
    label: "Atrasos Produção",
    link: "/app/producao",
    icon: <FontAwesomeIcon icon={faCalendarTimes} />,
    children: [
      { label: "Clientes", link: "/app/producao/atraso" },
      { label: "Atlético", link: "/app/producao/atletico" },
      { label: "Cruzeiro", link: "/app/producao/cruzeiro" },
    ],
  },
  { id: 4, type: "divider" },
  { id: 5, type: "title", label: "SUPORTE" },
  {
    id: 6,
    label: "Segundo Dropdown",
    link: "app/teste",
    icon:<FontAwesomeIcon icon={faCalendarTimes} /> ,
    children: [
      { label: "teste", link: "/app/profile" },
      { label: "teste", link: "/app/dashboard" },
      { label: "teste", link: "/app/ui/icons" },
    ],
  },
  {
    id: 7,
    label: "Cliente",
    link: "",
    icon:<FontAwesomeIcon icon={faUsers} /> ,
  },
  // { id: 7, label: "Library", link: "", icon: faCalendarTimes },
  // { id: 8, label: "Support", link: "", icon: faCalendarTimes },
  // { id: 9, label: "FAQ", link: "", icon: faCalendarTimes },
  // { id: 10, type: "divider" },
  // { id: 11, type: "title", label: "PROJECTS" },
  // {
  //   id: 12,
  //   label: "My recent",
  //   link: "",
  //   icon: faCalendarTimes,
  // },
  // { id: 4, type: "divider" },
  // { id: 6, type: "title", label: "HELP" },
  // { id: 7, label: "Library", link: "", icon: <LibraryIcon /> },
  // { id: 8, label: "Support", link: "", icon: <SupportIcon /> },
  // { id: 9, label: "FAQ", link: "", icon: <FAQIcon /> },
  // { id: 10, type: "divider" },
  // { id: 11, type: "title", label: "PROJECTS" },
  {
    id: 12,
    label: "My recent",
    link: "",
    icon: <Dot size="large" color="error" />,
  },
  {
    id: 13,
    label: "Starred",
    link: "",
    icon: <Dot size="large" color="danger" />,
  },
  {
    id: 14,
    label: "Background",
    link: "/cliente",
    icon: <Dot size="large" color="secondary" />,
  },
  // {
  //   id: 15,
  //   label: "UI Elements",
  //   link: "/app/ui",
  //   icon: <UIElementsIcon />,
  //   children: [
  //     { label: "Icons", link: "/app/ui/icons" },
  //     { label: "Charts", link: "/app/ui/charts" },
  //     { label: "Maps", link: "/app/ui/maps" },
  //   ],
  // },
];

function SideBar(props) {
  const location = useLocation();
  const { sidebarResponsive } = useContext(AuthContext);
  const [openTeste, setOpenTeste] = useState(false);

  useEffect(() => {
    function teste() {
      if (location.pathname === "/admin/producao/teste") {
        setOpenTeste(true);

      }
    }
    teste();
  }, []);
  return (

    <Sidebar  isResponsive={sidebarResponsive}>
      {/* <LogoContainer>
        <Avatar src={!sidebarResponsive  ? AvatarImage : Simbol} /> 
 
      </LogoContainer> */}
      {/* <MenuComponent /> */}

      <LinksContainer >
        <Links >
          <List
          // className={classes.sidebarList}
          >
            {structure.map(link => (
              <SidebarLink
                key={link.id}
                location={location}
                // isSidebarOpened={!sidebarResponsive}
                isSidebarOpened={true}
                {...link}
                
              />
            ))}
          </List>
          {/* <List
          // className={classes.sidebarList}
          >
            {structure.map(link => (
              <Box key={link.id}>
                <RouterLink to={link.link} >
                  <Link active={location.pathname === link.link ? true : false}>
                    <Box sx={{ width: "40px" }}>
                      <FontAwesomeIcon icon={link.icon} />
                    </Box>
                    <Title sidebarResponsive={sidebarResponsive}>
                      <Typography noWrap={true}   >{link.label}</Typography>
                    </Title>
                  </Link>
                </RouterLink>

              </Box>
              // <SidebarLink
              //   key={link.id}
              //   location={location}
              //   isSidebarOpened={isSidebarOpened}
              //   {...link}
              // />
            ))}
          </List> */}
       






        </Links>
      </LinksContainer>

    </Sidebar>

  );
}

export default SideBar;


// <RouterLink to="/admin/dashboard" >
// <Link active={location.pathname === "/admin/dashboard" ? true : false}>
//   <Box sx={{ width: "40px" }}>
//     <FontAwesomeIcon icon={faHome} />
//   </Box>
//   <Title sidebarResponsive={sidebarResponsive}>
//     <Typography noWrap={true}   >Página inicial </Typography>
//   </Title>
// </Link>
// </RouterLink>

// <RouterLink to="/admin/perfil" >
// <Link active={location.pathname === "/admin/perfil" ? true : false}>
//   <Box sx={{ width: "40px" }}>
//     <FontAwesomeIcon icon={faUserAlt} />
//   </Box>
//   <Title sidebarResponsive={sidebarResponsive}>
//     <Typography noWrap={true} >Meu Perfil </Typography>
//   </Title>


// </Link>
// </RouterLink>

// <RouterLink to="/admin/usuarios" >
// <Link active={location.pathname === "/admin/usuarios" ? true : false}>
//   <Box sx={{ width: "40px" }}>
//     <FontAwesomeIcon icon={faUsers} />
//   </Box>
//   <Title sidebarResponsive={sidebarResponsive}>
//     <Typography noWrap={true} >Usuários</Typography>
//   </Title>
// </Link>
// </RouterLink>

// <PermissionComponent role={["teste2"]}>
// <Link active={location.pathname === "" ? true : false}>
//   <Box sx={{ width: "40px" }}>
//     <FontAwesomeIcon icon={faUsers} />
//   </Box>
//   <Title sidebarResponsive={sidebarResponsive}>
//     <Typography noWrap={true} >Clientes</Typography>
//   </Title>
// </Link>
// </PermissionComponent>

// <RouterLink to="/admin/producao/atraso" >
// <Link active={location.pathname === "/admin/producao/atraso" ? true : false}>
//   <Box sx={{ width: "40px" }}>
//     <FontAwesomeIcon icon={faCalendarTimes} />
//   </Box>
//   <Title sidebarResponsive={sidebarResponsive}>
//     <Typography noWrap={true} >Atrasos Produção</Typography>
//   </Title>


// </Link>
// </RouterLink>

// <RouterLink to="#" onClick={() => { setOpenTeste(!openTeste) }}>
// <Link active={location.pathname === "/admin/producao/teste" ? true : false} >
//   <Box sx={{ width: "40px" }}>
//     <FontAwesomeIcon icon={faClipboardList} />
//   </Box>
//   <Title sidebarResponsive={sidebarResponsive}>
//     <Typography noWrap={true} >Teste Times</Typography>
//   </Title>
//   <Box sx={{
//     position: 'absolute',
//     top: '25%',
//     left: '90%',

//   }}>
//     {openTeste ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />}
//   </Box>
// </Link>
// </RouterLink>

// <Submenu openTeste={openTeste}>
// <RouterLink to="#">
//   <SubLink active={location.pathname === "/admin/producao/teste" ? true : false}>
//     {/* <Box sx={{width:"100px",border:"1px solid red"}}>
//   <FontAwesomeIcon icon={faCalendarTimes}  />
//   </Box> */}

//     <Typography noWrap={true} variant="subtitle2" >Atlético</Typography>

//   </SubLink>
// </RouterLink>
// <SubLink
//   active={location.pathname === "/admin/producao/teste" ? false : false}>
//   {/* <Box sx={{width:"40px"}}>
//   <FontAwesomeIcon icon={faCalendarTimes}  />
//   </Box> */}

//   <Typography noWrap={true} variant="subtitle2" >Cruzeiro</Typography>

// </SubLink>
// </Submenu>

// <RouterLink to="#">
// <Link active={location.pathname === "/cliente32" ? true : false}>
//   <Box sx={{ width: "40px" }}>
//     <FontAwesomeIcon icon={faClipboardList} />
//   </Box>
//   <Title sidebarResponsive={sidebarResponsive}>
//     <Typography noWrap={true} >Tipos</Typography>
//   </Title>
// </Link>
// </RouterLink>