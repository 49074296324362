import styled from 'styled-components';

export const Warrper = styled.div`
    width:100%;
    height:100%;
    background:#f6f7ff;
    padding:10px 30px;    
    overflow-y:auto;    
    transition:all 1s;  
    

`;



export const ContainerContent = styled.div`
    box-sizing: border-box;
    width:100%;     
    transition:all 0.5s;

      
`;
export const PanelRow = styled.div`
    box-sizing: border-box;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:row;
    width:100%;     
    transition:all 0.5s;

      
`;