import * as React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVert from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ExportXLSX from '../../components/Export/ExportXLSX';

export default function MenuDropDownBasic({data,filename,headColumn}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title="Exportar" arrow>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
             <MoreVert />
      </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >       
            <ExportXLSX data={data} filename={filename} headColumn={headColumn} acceptButtonLabel="Exportar Excel"  extension="xlsx"/>
            <ExportXLSX data={data} filename={filename} headColumn={headColumn} acceptButtonLabel="Exportar CSV" extension="csv"/>

      </Menu>
    </div>
  );
}
