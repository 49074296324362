import React,{useState} from "react";
import MenuItem from '@material-ui/core/MenuItem';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx/xlsx.mjs'




const ExportXLSX = (props
    // { csvData, fileName, wscols }
    ) => {
    const [headers, setHeaders] = useState([customersHead()]);

//   const headers = [
//     { label: "First Name", key: "firstName" },
//     { label: "Last Name", key: "lastName" },
//     { label: "Email", key: "email" },
//     { label: "Address", key: "address" },
//     { label: "Postcode", key: "postcode" }
//   ];

//   function customersData() {
//     const custs = [];
//     for (let i = 0; i <= 25; i++) {
//       custs[i] = {
//         firstName: `firstname${i}`,
//         lastName: `lastname${i}`,
//         email: `mail${i}@mail.com`,
//         address: `#${i}, block name, floor #${i} street name, city name, state name`,
//         postcode: `${i}0000`
//       };
//     }
//     return custs;
//   }

//   const wscols2 = [
//     { wch: Math.max(...props.data.map(customer => customer.firstName.length)) },
//     { wch: Math.max(...props.map(customer => customer.lastName.length)) },
//     { wch: Math.max(...props.map(customer => customer.email.length)) },
//     { wch: Math.max(...props.map(customer => customer.address.length)) },
//     { wch: Math.max(...props.map(customer => customer.postcode.length)) + 3
//     }
//   ];
  // ******** XLSX with object key as header *************
  // const fileType =
  //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  // const fileExtension = ".xlsx";

  // const exportToCSV = (csvData, fileName) => {
  //   const ws = XLSX.utils.json_to_sheet(csvData);
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(data, fileName + fileExtension);
  // };

  // ******** XLSX with new header *************
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = "."+props.extension;



  function customersHead() {  
    const Heading = {};
    props.headColumn.map(column =>{
        Heading[column.label] = column.label;
    })
    return Heading;
  }

  const exportToCSV = (customers, fileName) => {


    const ws = XLSX.utils.json_to_sheet(headers, {
      skipHeader: true,
      origin: 0 //ok
    });
     ws["!cols"] = [
        {wch: 15},
        {wch: 15},
        {wch: 15},
        {wch: 15},
        {wch: 15},
        {wch: 15},
        {wch: 15},
        {wch: 15}    
    ];

    XLSX.utils.sheet_add_json(ws, customers, {
      skipHeader: true,
      origin: -1 //ok
    });
  
    const wb = { Sheets: { Modelo: ws }, SheetNames: ["Modelo"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
     saveAs(data, fileName + fileExtension);
  };

  return (
    <MenuItem
      onClick={(e) => exportToCSV(props.data, props.filename)}
    >
     {props.acceptButtonLabel}
    </MenuItem>
  );
};

export default ExportXLSX;

// This component is a presentational component which takes the data to download and file name as props. The exportToCSV method is invoked when the export button is clicked on line 20.
