import React from "react";
import { toast } from 'react-toastify';
import { useState, createContext, useEffect, useCallback } from "react";
import api from "../services/api";
export const AuthContext = createContext({});

function AuthProvider({ children }) {
  const [userData, setUserData] = useState(null);
  // const [userPermissions, setUserPermissions] = useState();
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [badAuth, setBadAuth] = useState(false);
  const toastId = React.useRef(null);
  const customId = "custom-id-signin";
  const [sidebarResponsive, setSidebarResponsive] = useState(false);

  useEffect(() => {
    setLoading(true);
    async  function loadStorage() {
      const storageUser = localStorage.getItem('@PermissionYT:token');      
       if (storageUser) {
       api.defaults.headers.Authorization = `Bearer ${JSON.parse(storageUser).token}`;
       await   api.post("/me")
             .then(user => {  
              setUserData(user.data)                   
             }).catch(error => {
                console.log(error.response);                
                signOut();
             });
      }
        setLoading(false);       
    }
    loadStorage();
  }, [])

  //Fazendo login
  async function signIn(user) {
    setBadAuth(false);
    setLoadingAuth(true);
    toast.dismiss(toastId.current);
    const auth = await api
      //autenticação
      .post("/auth", user)
      .then(async value => {     
        const token = value.data.token;        
        api.defaults.headers.Authorization = `Bearer ${token}`;
        //retorna os dados do usuario autenticado
        await api
        .post("/me")
        .then((response) => {
               const userProfile = response.data;
            let data = {
              token: token
            };
            setTimeout(() => {
              setUserData(userProfile);
              storageUser(data);
              setLoadingAuth(false);              
              toastId.current = toast.success("Bem vindo, " + userProfile.name, {
                toastId: customId
              });
            }, 1000);
            
          })
          .catch((error) => {
            setTimeout(() => {
              setLoadingAuth(false);
            }, 1000);
          });

          return "sucess"
      })
      .catch(error => {          
          setBadAuth(true);
          setLoadingAuth(false);
        return error.response;

      });

      return auth;

  }

  const userLogged = useCallback(() => {
    const token = localStorage.getItem("@PermissionYT:token");
    if (token) {
      return true;
    }
    return false;
  }, []);


  async function signOut() {
    await api
    .post("/logout").then(()=>{
      localStorage.removeItem('@PermissionYT:token');
      setUserData(null);
      api.defaults.headers.Authorization = null;
      toast.dismiss(toastId.current);
      
    }).catch(()=>{
        localStorage.removeItem('@PermissionYT:token');
        setUserData(null);
        api.defaults.headers.Authorization = null;
        toast.dismiss(toastId.current);

    });
  }
  function storageUser(data) {
    localStorage.setItem('@PermissionYT:token', JSON.stringify(data));
  }

  function responsiveSidebar() {

    setSidebarResponsive(!sidebarResponsive);


  };



  return (
    <AuthContext.Provider
      value={{
        signed: !!userData,
        userData,
        loading,
        signIn,
        loadingAuth,
        signOut,
        badAuth,
        userLogged,
        sidebarResponsive,
        responsiveSidebar
      }}>
      {children}
    </AuthContext.Provider>
  )
}
export default AuthProvider;