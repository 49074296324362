import { useContext, useState, useRef, useEffect } from 'react';
import { AuthContext } from '../../contexts/auth';
import api from "../../services/api";
import { toast } from 'react-toastify';
import { ThemeProvider } from '@material-ui/core/styles';
import { ProfileParent, Profile, ProfileImg,  ProfileForm } from './styles';
import { GetUser } from '../../Utils/User'
import {
  Box,
  Grow,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
  Paper
} from "@material-ui/core";
import { Visibility, VisibilityOff, Save } from '@material-ui/icons';
import MyTheme from '../../themes';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import ProfileImage from '../../assets/Profile.png';
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccountProfile } from './account-profile';


function MyProfile() {

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordConfirmed, setShowPasswordConfirmed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingPut, setLoadingPut] = useState(false);
  const { userData } = useContext(AuthContext);
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const toastId = useRef(null);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickShowPasswordConfirmed = () => setShowPasswordConfirmed(!showNewPasswordConfirmed);
  useEffect(() => {
    async function loadUsuario() {
      await GetUser(userData.id)
        .then((e) => {
          setEmail(e.email);
          setName(e.name);
          setLoading(false);
        }).catch(error => {
          console.log(error);
          setLoading(false);
        });
    }
    loadUsuario();
  }, [userData.id]);

  const validationSchema = Yup.object({
    name: Yup
      .string('Enter your nome')
      .required('Nome is required'),
    newpassword: Yup
      .string('Enter your password')
      .min(8, 'Password should be of minimum 8 characters length'),
    confirmationpassword: Yup.string().when('newpassword', (newpassword, field) =>
      newpassword ? field.required('Password is required').oneOf([Yup.ref('newpassword')], 'Passwords must match') : field
    ),
  });

  async function handlePutData(values) {
    return (
      api.put("/user/" + userData.id, values)
        .then((e) => {
          return e;
        }).catch(error => {
          return 'error' + error;
        }));
  };
  const onSubmit = async (values, { setFieldValue }) => {    
    setLoadingPut(true);
    const { name :nameField, newpassword: currentPassword, confirmationpassword: newPassword } = values;
    var valueData = "";
    const dividirNome = nameField.split(" ");
    for (let i = 0; i < dividirNome.length; i++) {
      dividirNome[i] = dividirNome[i][0].toUpperCase() + dividirNome[i].substr(1);
    }
    var nameCapitalize = dividirNome.join(" ");
    if (currentPassword !== '' || newPassword !== '') {
      if (currentPassword === newPassword) {
        valueData = {
          "name": nameCapitalize,
          "currentPassword": currentPassword,
          "newPassword": newPassword
        };
      }
    } else {
      valueData = {
        "name": nameCapitalize
      };
    }
    const apiPut = await handlePutData(valueData);
    setName(apiPut.data.name);
    if (apiPut !== 'error') {
      setFieldValue("newpassword", "")
      setFieldValue("confirmationpassword", "")      
      setTimeout(() => {
        toastId.current = toast.success("Perfil atualizado com sucesso!");
        setLoadingPut(false);
      }, 1000);

    }
    if (apiPut === 'error') {
      setTimeout(() => {
        toastId.current = toast.error("Error ao atualizar perfil");
        setLoadingPut(false);
      }, 1000);

    }
  };

  const formik = useFormik({
    initialValues: {
      name: name,
      newpassword: '',
      confirmationpassword: ''
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    //  (values) => {
    //   alert(JSON.stringify(values, null, 2));
    // },
  });

  if (loading) {
    return (
      <CircularProgress color="inherit" /> 
    )
   }

  return (

    <ThemeProvider theme={MyTheme}>
      <ProfileParent>
        <Profile>
          <Grow in={true} timeout={400}>
            <ProfileImg>
              <Paper sx={{ width: '100%' }}>
                <AccountProfile name={name} group={userData.group_user.name} imgProfile={ProfileImage} />
              </Paper>
            </ProfileImg>
          </Grow>
          <ProfileForm>
            <Paper sx={{ width: '100%', p: 4 }}>
              <Box component="form" onSubmit={formik.handleSubmit} noValidate >
                <Grow in={true} timeout={400}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    disabled
                    value={email}

                  />
                </Grow>
                <Grow in={true} timeout={450}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="name"
                    name="name"
                    autoComplete="off"
                    value={formik.values.name}
                    disabled={loadingPut}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    inputProps={{ style: { textTransform: "capitalize" } }}
                  />
                </Grow>
                <Grow in={true} timeout={500}>
                  <TextField
                    margin="normal"
                    fullWidth
                    value={formik.values.newpassword}
                    disabled={loadingPut}
                    onChange={formik.handleChange}
                    error={formik.touched.newpassword && Boolean(formik.errors.newpassword)}
                    helperText={formik.touched.newpassword && formik.errors.newpassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPassword}
                            edge="end"

                          >
                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    name="newpassword"
                    label="Nova senha"
                    type={showNewPassword ? "text" : "password"}
                    id="outlined-adornment-newpassword"
                    autoComplete="newpassword"

                  />
                </Grow>
                <Grow in={true} timeout={550}>
                  <TextField
                    margin="normal"
                    fullWidth
                    value={formik.values.confirmationpassword}
                    onChange={formik.handleChange}
                    disabled={loadingPut}
                    error={formik.touched.confirmationpassword && Boolean(formik.errors.confirmationpassword)}
                    helperText={formik.touched.confirmationpassword && formik.errors.confirmationpassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPasswordConfirmed}

                            edge="end"
                          >
                            {showNewPasswordConfirmed ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    name="confirmationpassword"
                    label="Confirmar nova senha"
                    type={showNewPasswordConfirmed ? "text" : "password"}
                    id="outlined-adornment-confirmationpassword"
                    autoComplete="confirmationpassword"

                  />
                </Grow>

                <Box sx={{ position: 'relative' }}>
                  <Grow in={true} timeout={700}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 1, mb: -1 }}
                      startIcon={<Save fontSize="small" />}
                      disabled={loadingPut}
                    >

                      Salvar
                    </Button>
                  </Grow>
                  {loadingPut && (
                    <CircularProgress
                      size={20}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                      }}

                    />
                  )}
                </Box>

              </Box>
            </Paper>
          </ProfileForm>
        </Profile>
      </ProfileParent>
    </ThemeProvider>
  );
}

export default MyProfile;