import React from 'react';
import MUICookieConsent from 'material-ui-cookie-consent';
import {Box} from '@material-ui/core';
function CookiesAccept(props) {





    return (

        <>
            <MUICookieConsent
                cookieName="CookieConsent"
                componentType="Snackbar" // default value is Snackbar
                message="Nós usamos cookies, eles são usados para aprimorar a sua experiência. Ao fechar este banner ou continuar na página, você concorda com o uso de cookies"

            />


        </>
    );
}

export default CookiesAccept;