import React from "react";
import {
    Route,
    Switch,
    Redirect,
    withRouter,
} from "react-router-dom";
import ContainerWarrper from '../components/ContainerWarrper';
import SideBar from '../components/SideBar';
import Main from '../components/Main';
import MainContent from '../components/MainContent';
import HeaderContainer from '../components/HeaderContainer';
import { Profile, Usuario, Error405, Error404, Dashboard, SignIn, ProducaoAtraso } from '../pages';
import PrivateRoutes from '../routes/PrivateRoutes';
export const AdminDashboard = ({ children, ...props }) => {
                                                                                                                                                                                                                                                                                                                                                                                                                    

    return (
        <>
            <HeaderContainer />
            <ContainerWarrper>
                <SideBar />
                <Main>
                    <MainContent title={props.title}>
                        <Switch>
                            <Route
                                exact
                                path="/app"
                                render={() => <Redirect to="/app/dashboard" />}
                            />
                            <Route path="/app/dashboard" component={Dashboard} />
                            <PrivateRoutes path="/app/perfil" component={Profile} role={["teste"]}/>
                            <Route path="/app/usuarios" component={Usuario} />
                            <Route path="/app/producao/atraso" component={ProducaoAtraso} />
                            <Route path="/app/ui/icons" component={Error405} />
                        </Switch>
                    </MainContent>
                </Main>
            </ContainerWarrper>
        </>
    )

}