import purple from '@material-ui/core/colors/purple';
export default {
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          // backgroundColor: '#F3F4F6',
          '.MuiTableCell-root': {
            color: '#374151'
          },
          borderBottom: 'none',
          '& .MuiTableCell-root': {
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: 1,
            letterSpacing: 0.5,
            textTransform: 'uppercase'
          },
          '& .MuiTableCell-paddingCheckbox': {
            paddingTop: 4,
            paddingBottom: 4
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          boxShadow: "0px 3px 11px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a"

        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            padding: 16,
          },
        },
      },
    },
    MuiInputInputTypeSearch: {
      styleOverrides: {
        root: {
          color: "red",
          clearIndicator: {
            color: "red"
          },
        }
      }
    },
  },

  shape: {
    borderRadius: 4
  },
  palette: {
    mode: 'light',
    primary: { // works
      main: 'rgb(37, 47, 62)',
      contrastText: '#fff',
    },
    secondary: { // works
      main: '#00c6c7',
      contrastText: '#fff',
    },
    success: { // works
      main: 'rgb(46, 125, 50)',
      contrastText: '#fff',
    },
    error: { // works
      main: '#FF0000',
      contrastText: '#fff',
    },
    lightError: { // works
      main: '#fad7dd',
      contrastText: '#e63757',
    },
    lightSuccess: { // works
      main: '#ccf7e5',
      contrastText: '#00d97e',
    },
    warning: { // works
      main: '#fdf3d9',
      contrastText: '#f6c343',
    },
    info: { // works
      main: '#d7eff6',
      contrastText: '#39afd1',
    },
    danger: { // works
      main: '#FF0000',
      contrastText: '#fff',
    },
    white: { // works
      main: 'white',
    },
    companyBlue: {
      backgroundColor: '#fff',
      color: '#fff',
    },
    companyRed: {
      backgroundColor: '#E44D69',
      color: '#000',
    },
    accent: {
      backgroundColor: purple[500],
      color: '#000',
    },
  },
  typography: {
    button: {
    },
    fontFamily: '"Roboto", sans-serif',
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.57
    },
    subtitle1: {
      fontSize: '1rem',
      lineHeight: 1.75
    },
    subtitle2: {
      fontSize: '0.875rem',
      lineHeight: 1.57
    },
    overline: {
      fontSize: '0.75rem',
      letterSpacing: '0.5px',
      lineHeight: 2.5,
      textTransform: 'uppercase'
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.66
    },
    h1: {
      fontWeight: 400,
      fontSize: '3rem',
      lineHeight: 1.375
    },
    h2: {
      fontWeight: 400,
      fontSize: '2.4rem',
      lineHeight: 1.167
    },
    h3: {
      fontWeight: 400,
      fontSize: '1.64rem',
      lineHeight: 1.375
    },
    h4: {
      fontWeight: 400,
      fontSize: '1.5rem',
      lineHeight: 1.375
    },
    h5: {

      fontSize: '1.285rem',
      lineHeight: 1.375
    },
    h6: {

      fontSize: '1.142rem',
      lineHeight: 1.375
    }
  }
  // components: {
  //   MuiCssBaseline: {
  //     styleOverrides: `
  //       input:-webkit-autofill,
  //       input:-webkit-autofill:hover, 
  //       input:-webkit-autofill:focus,
  //       textarea:-webkit-autofill,
  //       textarea:-webkit-autofill:hover,
  //       textarea:-webkit-autofill:focus,
  //       select:-webkit-autofill,
  //       select:-webkit-autofill:hover,
  //       select:-webkit-autofill:focus {      

  //         transition: background-color 5000s ease-in-out 0s;
  //       } 
  //       `,
  //   },
  // },
};
