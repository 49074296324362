import React from 'react';
import { AdminDashboard } from '../../layouts/AdminDashboard';

import Box from '@material-ui/core/Box';

import Divider from '@material-ui/core/Divider';

import Toolbar from '@material-ui/core/Toolbar';




export const Error405 = ()=> {
    return (
      <>
 {/* <AdminDashboard title="Error 407"> */}
      <div>
        <header>
          
          <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${300}px)` } }}
      >
        <Toolbar />
        <Divider />
        teste
        <Divider />
        
      </Box>
    <h1>Error 407</h1>
        </header>
      </div>
      {/* </AdminDashboard> */}
      </>
    );
  }
