import { useContext, } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from '../contexts/auth';
import { Backdrop, CircularProgress } from '@material-ui/core';

export default function PublicRoute({
    component: Component,
    isPrivate,
    ...rest

}) {
    const { signed ,loading} = useContext(AuthContext);     
    const from  = rest.location.pathname;
    if(loading){
        return (
            <div>
                <Backdrop
                    sx={{ background: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }
    if(!signed && isPrivate){return <Redirect to={{ pathname: '/', state: { from: from } }} />   }
    if(signed && !isPrivate){return <Redirect to={{ pathname: '/app/dashboard', state: { from: from } }} />}
    return (
        <Route
            {...rest}
            render={props => (

                <Component {...props} />
            )}
        />

    )
}