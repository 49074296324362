import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PublicRoute from './PublicRoute';
// import CustomRoute from './CustomRoute';
import PrivateRoutes from './PrivateRoutes';
import { Profile, Usuario, Error405, Error404, Dashboard, SignIn, ProducaoAtraso } from '../pages';
import { ViewCliente } from '../layouts/ViewCliente';
import { AdminDashboard } from '../layouts/AdminDashboard';

export default function Routes() {
    return (
        <Switch>
            <Route exact path="/" render={() => <Redirect to="/login" />} />
            <PublicRoute exact path="/login" component={SignIn} />
            <PrivateRoutes path="/app" component={AdminDashboard}  />
            <PrivateRoutes path="/cliente" component={ViewCliente}  />
            {/* <PrivateRoutes exact path="/admin/dashboard" component={Dashboard} role={["teste"]} />
            <PrivateRoutes path="/admin/perfil" component={Profile} role={["teste"]} />
            <PrivateRoutes path="/admin/usuarios" component={Usuario} role={["teste2222", "teste"]} />
            <PrivateRoutes path="/admin/producao/atraso/:nome" component={ProducaoAtraso} role={["teste2222", "teste"]} />
            <PrivateRoutes path="/admin/producao/atraso" component={ProducaoAtraso} role={["teste2222", "teste"]} />
            <ViewCliente>
                <Switch>
                    <PrivateRoutes exact path="/cliente" component={() => <h1>cliente2</h1>} />
                </Switch>
            </ViewCliente>
            <PrivateRoutes exact path="/cliente2" component={() => <h1>cliente2</h1>} />
            <PrivateRoutes exact path="/cliente32" component={Error405} /> */}
            <Route component={Error404} />

        </Switch>
    )
}