import api from "../services/api";

export async function GetUser(id) {

    const response = await api.get("/user/" + id);

    return response.data
}

export async function PostCreateUser(values) {
   const CreateUser =  await api.post("/user",values)
    .then((e)=>{
        return e
    }).catch(error => {

          return error.response.data
      });

    return CreateUser
}

export async function EditUser(id,values) {
    return (            
        await  api.put("/user/" + id , values)        
      .then((e) => {
               return e ;
      }).catch(error => {     

        return Promise.reject(error.response.data);
      })

      );
    
}
export async function GetUserAll(limit,page,filter) {
        ///user?limit=2&offset=1&filter=email:like:%teste422s4%;name:like:%teste422s4%
    const response = await api.get("/user?limit="+limit+"&page="+page+"&"+filter);
    return response.data
}



export function HelloTester(e) {

    console.log(e.target.dataset.target);
}