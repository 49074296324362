import api from "../services/api";

export async function GetUser(id) {

    const response = await api.get("/user/" + id);

    return response.data
}

export async function EditUser(id,values) {
    return (            
        await  api.put("/user/" + id , values)        
      .then((e) => {
               return e ;
      }).catch(error => {     

        return Promise.reject(error.response.data);
      })

      );
    
}
export async function GetGroupAll(limit,page) {
        ///user?limit=2&offset=1&filter=email:like:%teste422s4%;name:like:%teste422s4%
    const response = await api.get("/group-user?limit="+limit+"&page="+page);
    return response.data
}



export function HelloTester(e) {

    console.log(e.target.dataset.target);
}