import { Container } from './styles';

function ContainerWarrper(props) {



    return (
      
    <Container>
  {props.children}
    </Container>
      
    );
  }
  
  export default ContainerWarrper;