import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from '../../../contexts/auth';
import classNames from "classnames";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from "@material-ui/core";
// styles
import useStyles, { ItemText } from './styles';

//components
import Dot from "../Dot";

export default function SidebarLink({
  link,
  icon,
  label,
  children,
  location,
  isSidebarOpened,
  nested,
  type
}) {

  const { sidebarResponsive } = useContext(AuthContext);
  var classes = useStyles();
  // local
  var [isOpen, setIsOpen] = useState(false);
  var isLinkActive = link && (location.pathname === link || location.pathname.indexOf(link) !== -1);

  if (type === "title")
    return (
      <Typography      
        variant="h5"
        className={classNames(classes.linkText, classes.sectionTitle, {
        })}
      >
        {label}
      </Typography>
    );
  if (type === "divider") return <Divider className={classes.divider} />;

  if (!children)
    return (
      <ListItem
        button
        component={link && Link}
        to={link}
        classes={{
          root: classNames(classes.link, {
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNestedActive]: isLinkActive && nested,
            [classes.linkNested]: nested,
          }),
        }}
      >
        <ListItemIcon
          className={classNames(classes.linkIcon, {
            [classes.linkIconActive]: isLinkActive,
          })}
        >
          {nested ? <Dot color={isLinkActive && "white"} /> : icon}
        </ListItemIcon>
        <ItemText
          sx={{ opacity: sidebarResponsive ? "0" : "1" }}
          classes={{
            primary: classNames(classes.linkText, {
              [classes.linkTextActive]: isLinkActive,
            }),
          }}
          primary={label}
        />
      </ListItem>
    );

  return (
    <>
      <ListItem
        button
        component={link && Link}
        onClick={toggleCollapse}
        classes={{
          root: classNames(classes.link, {
            [classes.linkActive]: isLinkActive,
            [classes.linkNested]: nested,
          }),
        }}
        to={link}
      >
        <ListItemIcon
          className={classNames(classes.linkIcon, {
            [classes.linkIconActive]: isLinkActive,
          })}
        >
          {icon}
        </ListItemIcon>
        <ItemText
          sx={{ opacity: sidebarResponsive ? "0" : "1" }}
          classes={{
            primary: classNames(classes.linkText, {
              [classes.linkTextActive]: isLinkActive,
            }),
          }}
          primary={label}
        />
        <ListItemIcon className={classes.linkIcon}  >
          {isOpen && isSidebarOpened ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleLeft} />}
        </ListItemIcon>
      </ListItem>
      {children && (
        <Collapse
          in={isOpen && isSidebarOpened}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding
            sx={{ pl: 0, backgroundColor: "#2c3b41" }}
          >
            {children.map(childrenLink => (
              <SidebarLink
                key={childrenLink && childrenLink.link}
                location={location}
                isSidebarOpened={isSidebarOpened}
                // classes={classes}
                nested
                {...childrenLink}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );

  // ###########################################################

  function toggleCollapse(e) {
    if (isSidebarOpened) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  }
}
