import styled from 'styled-components';

export const WarrperHeader = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    /* background: rgba(0,0,0,0.03); */
    margin:15px 0;
    width:100%;
    height:50px;
    overflow-y:auto;    
    /* padding:0 5px; */
    transition:all 0.5s;
`;