import React,{useEffect,useState} from 'react';
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon, 
  Grow,
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
  Paper
} from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {  GetGroupAll } from '../../Utils/Group';

function FiltroPesquisaUsuario(props) {
  const [groups,setGroups] = useState([{
    "id": "",
    "name": ""
  }]);
  useEffect(() => {
    async function loadNiveis() {
      await GetGroupAll("99",0)
        .then((e) => {
          setGroups(e);
          }).catch(error => {
          console.log(error);
        });
    }
    loadNiveis();
  }, []);
  return (

    <Box sx={{ mb: 2, width: "100%", transition: "border 200ms ease-out" }}>
      <Grow in={true} timeout={250}>
        <Card component={Paper}>
          <CardContent sx={{
            display: "flex", alignContent: "space-around", width: "100%",
            justifyContent:
            {
              xs: "space-between"
            },
            flexWrap: "wrap",
          }}
          >
            <Box
              sx={{
                maxWidth: {
                  xs: "100%",
                  md: "65%",
                },
                minWidth: {
                  xs: "100%",
                  md: "65%",
                },
                flexGrow: 1,
                mb:
                {
                  xs: 1,
                  md: 0,
                },
              }}
            >
              <TextField
                fullWidth
                name="seach"
                variant="outlined"
                type="search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        color="action"
                        fontSize="small"
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                  // endAdornment: (
                  //   <InputAdornment position="end">
                  //     <IconButton
                  //       // onClick={handleClickShowNewPassword}
                  //       edge="end"
                  //     >
                  //     <FontAwesomeIcon icon={faWindowClose} />
                  //     </IconButton>
                  //   </InputAdornment>
                  // )
                }}
                value={props.seach}
                placeholder="Pesquisar..."
                label="Pesquisar usuário"               
                id="pesquisar"
                onChange={props.changeFilter}
              />

            </Box>
            <Box sx={{
              maxWidth: {
                xs: "100%",
                sm: "50%",
                md: "17%",
              },
              minWidth: {
                xs: "100%",
                sm: "50%",
                md: "17%",
              },
              mb:
              {
                xs: 1,
                sm: 0,
              },
            }}>
              <FormControl fullWidth>
                <InputLabel>Nível</InputLabel>
                <Select
                  value={props.filterNivel}
                  name="nivel"
                  onChange={props.changeFilter}
                >
                  <MenuItem value="">Todos</MenuItem>
                    {groups.map(group => (
                  <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{
              maxWidth: {
                xs: "100%",
                sm: "47%",
                md: "15%",
              },
              minWidth: {
                xs: "100%",
                sm: "47%",
                md: "15%",
              },
            }}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  value={props.filterStatus}
                  name="status"
                  onChange={props.changeFilter}
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value={1}>Ativo</MenuItem>
                  <MenuItem value={2}>Desativado</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </CardContent>
        </Card>
      </Grow>
    </Box>
  );
}

export default FiltroPesquisaUsuario;