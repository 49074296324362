import styled from 'styled-components';

export const MainDiv = styled.div`
    display: block;
    width:${props => props.isresponsive ? "100%" : "calc(100% - 260px)"};
    height:100%;
    background-color: #fff;
    min-width:330px;
    transition:all 0.5s;
        
    @media(max-width: 800px) {
        transition:all 0s;
        width: 100% ;

    }
    
`;