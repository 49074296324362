import axios from "axios";


const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_AXIOS,
});

// const api = axios.create({
//   baseURL: "http://api.groupworking.com.br/api/v1",
// });


export default api;