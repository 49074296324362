import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Paper,
  Divider,
  Grow,
  InputAdornment,
  IconButton,
  CircularProgress
}
  from '@material-ui/core';
import useWindowWidth from "../../Utils/useWindowWidth";
import { Visibility, VisibilityOff } from '@material-ui/icons';
import LinearProgress from '../../components/LinearProgress';

import { ThemeProvider,useTheme  } from '@material-ui/core/styles';
import MyTheme from '../../themes';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AuthContext } from '../../contexts/auth';
// import { LoadingButton } from '@material-ui/lab';



const validationSchema = Yup.object({
  email: Yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: Yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
})


export const SignIn = () => {
  document.title = "Login";
  const { signIn, loadingAuth } = React.useContext(AuthContext);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const theme = useTheme();
  const width = useWindowWidth();


  const variantType = width < theme.breakpoints.values.sm ? "none" : "outlined";

  const onSubmit = async(values, actions) => {
    // console.log(JSON.stringify(values))
   const login = await signIn(values);
   if(login !== "sucess"){
    actions.setFieldError( "email", "Usuário inválido");
    actions.setFieldError( "password", "Senha inválida");

   }
  //  console.log(login);
  //  console.log(actions);

  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      toggle: false,
      checked: []
    },
    onSubmit: onSubmit,
    validationSchema: validationSchema,
  });
  return (

    <ThemeProvider theme={MyTheme}>
      <Box sx={{ width: '100%' }}>
        {loadingAuth && (
          <LinearProgress color="secondary" />
        )}
      </Box>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Grow in={true} timeout={300}>
          <Paper  sx={{
            my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 },
          }} >
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography component="h1" variant="h4">
                Login
              </Typography>
              {formik.errors.auth &&
              
              <Typography component="h1" variant="h4">
                Login
              </Typography>
              }
              <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                <Grow in={true} timeout={400}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    value={formik.values.email}
                    disabled={loadingAuth}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grow>
                <Grow in={true} timeout={500}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"

                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    id="outlined-adornment-password"
                    autoComplete="current-password"
                    value={formik.values.password}
                    disabled={loadingAuth}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                  />
                </Grow>
                <Grow in={true} timeout={600}>
                  <FormControlLabel
                    control={<Checkbox value={formik.values.toggle}
                      color="primary" name="toggle"
                      onChange={formik.handleChange}
                      disabled={loadingAuth} />}
                    sx={{ mt: 1 }}
                    label="Continuar conectado"
                  />
                </Grow>
                <Box sx={{ m: 1, position: 'relative' }}>
                  <Grow in={true} timeout={700}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 1, mb: 3 }}
                      disabled={loadingAuth}
                    >
                      Login
                    </Button>
                  </Grow>

                  {loadingAuth && (
                    <CircularProgress
                      size={20}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-18px',
                        marginLeft: '-12px',
                      }}

                    />
                  )}
                </Box>
                <Grow in={true} timeout={900}>
                  <Divider sx={{ mb: 2 }} />
                </Grow>
                <Grid container>
                  <Grid item xs>
                    <Grow in={true} timeout={1300}>

                      <Link variant="body2" color="secondary" underline="hover" component={RouterLink} to="/" >

                        Esqueceu a senha?

                      </Link>

                    </Grow>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>
      </Grow>
    </Container>
    </ThemeProvider >
  );
}

/*
import Stack, from '@material-ui/core/Stack';
import MuiAlert from '@material-ui/core/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

 <Stack spacing={2} sx={{ width: '100%' }}>
            <Alert severity="error">This is an error alert — check it out!</Alert>
            <MuiAlert severity="warning">This is a warning alert — check it out!</MuiAlert>
            <Alert severity="info">This is an info alert — check it out!</Alert>
            <Alert severity="success">This is a success alert — check it out!</Alert>
          </Stack>

*/