import React from 'react';
import { AdminDashboard } from '../../layouts/AdminDashboard';
import Box from '@material-ui/core/Box';
import PermissionComponent from "../../components/PermissionComponent";
import MyProfile from '../../components/MyProfile';
import ContentHeader from '../../components/ContentHeader';
export const Profile = () => {

  const title = "Meu Perfil";

  document.title = title; 
  return (
    <>
      {/* <AdminDashboard title="Meu Perfil"> */}

        <Box
          component="main"
          // sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${300}px)` }, border: "1px solid red" }}
          sx={{ width: "100%" }}
        >
      <ContentHeader title={title}/>
          <PermissionComponent role={["teste"]}>
            <MyProfile />

          </PermissionComponent>
        </Box>
      {/* </AdminDashboard> */}
</>
      )
  }
