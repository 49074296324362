import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { BackdropModal } from './styles';
import { makeStyles } from '@material-ui/styles';
import { GetUserAll } from '../../Utils/User'
import { GetGroupAll } from '../../Utils/Group';
import { PostCreateUser } from '../../Utils/User';
import { toast } from 'react-toastify';
import {
    Box,
    Modal,
    Button,
    Fade,
    Grow,
    Tooltip,
    TextField,
    IconButton,
    CircularProgress,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    Typography
} from "@material-ui/core";
import { PersonAdd, HighlightOff } from '@material-ui/icons';
const useStyles = makeStyles(theme => ({
    boxFlex: {
        // border:'1px solid blue',
        width: '100%',
        transition: 'all 1s ease-out',
        ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
            flexDirection: 'column',
            justifyContent: 'center'
        },
    },
    topModal: {
        // border:'1px solid green',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    boxInputTotal: {
        // border:'1px solid red',
        width: '100%',
        paddingBottom: '15px'

    },
    overflow: {
        overflow: 'auto',

    },
}));
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '500px',
    minWidth: '300px',
    width: '50%',
    transition: 'all 1s ease-out',
    bgcolor: 'background.paper',
    boxShadow: 3,
    borderRadius: 2,
    pt: 1,
    px: 2,
    pb: 1,
    ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
        minWidth: '300px',
    },

};


function ModalCreateUser(props) {

    const [groups, setGroups] = useState([{
        "id": "",
        "name": ""
    }]);
    const [loardCreate, setLoadCreate] = useState(false);
    const toastId = useRef(null);
    const customId = "custom-id-created-user";
    useEffect(() => {
        async function loadNiveis() {
            await GetGroupAll("99", 0)
                .then((e) => {
                    setGroups(e);
                }).catch(error => {
                    console.log(error);
                });
        }
        loadNiveis();
    }, []);



    const classes = useStyles();

    const onSubmit = async (values, actions) => {
        setLoadCreate(true);
        const { setFieldError, resetForm } = actions;
        const nomeSobreNome = values.name;
        const dividirNome = nomeSobreNome.split(" ");

        for (let i = 0; i < dividirNome.length; i++) {
            dividirNome[i] = dividirNome[i][0].toUpperCase() + dividirNome[i].substr(1);
        }
        values.name = dividirNome.join(" ");
        const response = await PostCreateUser(values);
        setTimeout(async function () {
            if (response.errors) {
                if (response.errors.name) {
                    setFieldError("name", response.errors.name);
                }
                if (response.errors.email) {
                    setFieldError("email", response.errors.email);
                }
                toastId.current = toast.error("Falha ao cadastrar usuário!!!", {
                    toastId: customId
                });
            }

            if (response.data) {
                toastId.current = toast.success(response.data.name + " cadastrado com sucesso!!!", {
                    toastId: customId
                });
                const filter = "seach=" + props.seach + (props.ilterNivel === 0 ? "" : "&group_id=" + props.filterNivel) + (props.filterStatus === 0 ? "" : "&status_id=" + props.filterStatus);
                const userAll = await GetUserAll(props.rowsPerPage, props.page, filter);
                props.setRows(userAll.data);
                props.setTotalUser(userAll.total_user);
                resetForm({
                    values: '',
                    // you can also set the other form states here
                });

            }
            setLoadCreate(false);
        }, 1000);


    };

    const validationSchema = Yup.object({
        name: Yup
            .string('Enter your nome')
            .required('Nome is required'),
        email: Yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required'),
        group_id: Yup
            .number('Nível is required')
            .required('Nível is required'),
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            group_id: "",
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    });
    return (
        <>
            <Modal
                keepMounted
                BackdropComponent={BackdropModal}
                open={props.openCreate}
                onClose={props.handleCloseCreateModal}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                BackdropProps={{
                    timeout: 500,
                }}
                sx={{ overflow: 'auto' }}
            >
                <Fade in={props.openCreate}>
                    <Box sx={{ ...style }}>
                        <Box className={classes.topModal} >
                            <Typography
                                color="textPrimary"
                                variant="h6"
                            >
                                Cadastrar usuário
                            </Typography>

                            <Tooltip title="Fechar" placement="right" >
                                <IconButton
                                    aria-label="close modal"
                                    onClick={props.handleCloseCreateModal}
                                    edge="end"
                                >
                                    <HighlightOff />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box className={classes.boxFlex} >
                            <Box component="form"
                                onSubmit={formik.handleSubmit}
                            >
                                <Box className={classes.boxInputTotal}>
                                    <Grow in={props.openCreate} timeout={450}>
                                        <TextField
                                            fullWidth
                                            id="name"
                                            label="Name"
                                            name="name"
                                            autoComplete="off"
                                            value={formik.values.name}
                                            disabled={loardCreate}
                                            onChange={formik.handleChange}
                                            error={formik.touched.name && Boolean(formik.errors.name)}
                                            helperText={formik.touched.name && formik.errors.name}
                                            inputProps={{ style: { textTransform: "capitalize" } }}
                                            autoFocus
                                        />
                                    </Grow>
                                </Box>
                                <Box className={classes.boxInputTotal} >
                                    <Grow in={props.openCreate} timeout={400}>
                                        <TextField
                                            fullWidth
                                            required
                                            id="email"
                                            label="Email"
                                            name="email"
                                            autoComplete="off"
                                            value={formik.values.email}
                                            disabled={loardCreate}
                                            onChange={formik.handleChange}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                        />
                                    </Grow>
                                </Box>
                                <Box className={classes.boxInputTotal}>
                                    <Grow in={props.openCreate} timeout={550}>
                                        <FormControl fullWidth>
                                            <InputLabel id="select-nivel-label-create">Nível</InputLabel>
                                            <Select
                                                disabled={loardCreate}
                                                fullWidth
                                                required
                                                labelId="select-nivel-create"
                                                id="group_id"
                                                value={formik.values.group_id}
                                                label="Nível"
                                                onChange={formik.handleChange}
                                                name="group_id"
                                            >
                                                <MenuItem value="">Selecione um nível</MenuItem>
                                                {groups.map(group => (
                                                    <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>
                                    </Grow>
                                </Box>
                                <Box className={classes.boxFlex}>
                                    <Box className={classes.boxInputTotal}>
                                        <Box sx={{ position: 'relative' }}>
                                            <Grow in={props.openCreate} timeout={700}>
                                                <Button
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    sx={{ mt: 1, mb: 1 }}
                                                    startIcon={<PersonAdd fontSize="small" />}
                                                    disabled={loardCreate}
                                                >
                                                    Cadastrar
                                                </Button>
                                            </Grow>
                                            {loardCreate && (
                                                <CircularProgress
                                                    size={20}
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '40%',
                                                        left: '50%',
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}

export default ModalCreateUser;