import styled from 'styled-components';
import { ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Sidebar } from '../styles';


export const ItemText = styled(ListItemText)`
      transition: all 0.5s;
      padding-left:1,5;
   ${Sidebar}:hover & {
    opacity: 1;
  }
`;

export default makeStyles(theme => ({
  link: {
    textDecoration: "none",
    width: "260px",
    transition: "all 0.5s",
    cursor: "pointer",
    // color: "rgb(184, 199, 206)",
    borderLeft: "3px solid transparent",
    "&  >*": {
      color: "#b8c7ce",
    },
    "&:hover": {
      backgroundColor: "#3b4452",
      borderLeft: "3px solid white",
      "&  >*": {
        color: "white",
      },
      
    },
  },
  linkActive: {
    backgroundColor: "rgb(30,40,44)",
    borderLeft: "3px solid white",
    cursor: "default",
    "&  >*": {
      color: "white",
    },
  },
  linkNested: {
    //  border: "1px solid red",
    
    "&:hover": {
    },
  },
  linkNestedActive: {
    backgroundColor: "#3b4452",
    borderLeft: "3px solid white",
    cursor: "default",
    "&  >*": {
      color: "white",
    },
  },
  linkIcon: {
    display: "flex", 
    justifyContent: "center",
    minWidth: "37px",
  },
  linkText: {
     paddingLeft:"8px",
     transition: "all 0.5s",
  },
  linkTextHidden: {
    opacity: 0,
  },
  sectionTitle: {
    marginLeft: "20px",
    marginTop: "16px",
    marginBottom: "16px",
    color: "white",
  },
  divider: {
    height: "1px",
    backgroundColor: "white",
    marginTop: "16px",
    marginBottom: "32px"
  },
}));


