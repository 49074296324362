import React from "react";
import {
    Route,
    Switch,
    Redirect,
    withRouter,
} from "react-router-dom";
import ContainerWarrper from '../components/ContainerWarrper';
import SideBar from '../components/SideBar';
import Main from '../components/Main';
import MainContent from '../components/MainContent';
import HeaderContainer from '../components/HeaderContainer';
import { Profile, Usuario, Error405, Error404, Dashboard, SignIn, ProducaoAtraso } from '../pages';

export const ViewCliente = ({ children, ...props }) => {
    document.title = props.title;

    return (
        <>
            <HeaderContainer />
            <ContainerWarrper>
                <SideBar />
                <Main>
                    <MainContent title="{props.title}">
                        <Switch>
                            <Route
                                exact
                                path="/cliente"
                                render={() => <Redirect to="/cliente/index" />}
                            />
                            <Route path="/cliente/index" component={Error405} />

                        </Switch>
                    </MainContent>
                </Main>
            </ContainerWarrper>
        </>
    )

}