import React from 'react';
import ReactDOM from 'react-dom';
import AuthProvider from './contexts/auth';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import GlobalStyle from './styles/global';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import Themes from "./themes";
import CookiesAccept from '../src/components/CookiesAccept';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
        <AuthProvider>
        <CssBaseline />
        <GlobalStyle />
        <ToastContainer autoClose={2500} theme="light" pauseOnFocusLoss={false} />
        <ThemeProvider theme={Themes.default}>
    <App />
    <CookiesAccept/>
        </ThemeProvider>
        </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

