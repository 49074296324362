import api from "../services/api";



export async function GetTotalAtrasoCliente(tipo="") {
   const totalAtrasoCliente =  await api.get("/total-atraso-cliente?tipo="+tipo)
    .then((e)=>{
        return e.data
    }).catch(error => {
          return error.response.data
      });

    return totalAtrasoCliente;
}

export async function GetAtrasoCliente(tipo="") {

    const AtrasoCliente =  await api.get("/atraso-cliente?tipo="+tipo)
     .then((e)=>{
         return e.data
     }).catch(error => {
           return error.response.data
       });
 
     return AtrasoCliente
 }
export function HelloTester(e) {

    console.log(e.target.dataset.target);
}
