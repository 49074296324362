import React from 'react';
import { UploadImg2 } from './styles';
import {
  Avatar,
  Box,
  Button,
  CardActions,
  CardContent,
  Divider,
  Typography
} from "@material-ui/core";
import { AddAPhoto } from '@material-ui/icons';
import { useState } from 'react';
const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  group: 'Administrador',
  name: 'Katarina Smith'
};
export const AccountProfile = (props) =>{

const [photo,setPhoto] =  useState(user.avatar);

 return(
  <Box sx={{
    width: '100%',
    
  }}>
    <CardContent>
      <Box
        sx={{
         alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Avatar
          src={photo}
          sx={{
            height: 120,
            mb: 2,
            width: 120
          }}
        />
        <Typography
          color="textPrimary"
          variant="h4"
        >
          {props.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body1"
        >
          {`${props.group}`}
        </Typography>
      </Box>
    </CardContent>
    <Divider />    
    <CardActions>
      <UploadImg2>
          <form encType="multipart/form-data">
        <label htmlFor="upload-photo" >
          <input
            style={{ display: "none" }}
            id="upload-photo"
            name="upload-photo"
            type="file"
            onChange={(event)=>{setPhoto(URL.createObjectURL(event.target.files[0]))}}
          />
          <Button color="primary" size="small" variant="text" component="span"
            fullWidth
            startIcon={<AddAPhoto fontSize="small" />}
          >
            Alterar Foto
          </Button>
        </label>
        </form>
      </UploadImg2>

    </CardActions>
  </Box>
);
}
