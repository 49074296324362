import api from "../services/api";



export async function GetTotalAtrasoAtletico() {
   const totalAtrasoAtletico =  await api.get("/total-atraso-atletico")
    .then((e)=>{
        return e.data
    }).catch(error => {
          return error.response.data
      });

    return totalAtrasoAtletico
}


export async function GetAtrasoAtletico() {
    const totalAtrasoAtletico =  await api.get("/atraso-atletico")
     .then((e)=>{
         return e.data
     }).catch(error => {
           return error.response.data
       });
 
     return totalAtrasoAtletico
 }


export function HelloTester(e) {

    console.log(e.target.dataset.target);
}