import { useContext } from 'react';
import { AuthContext } from '../../contexts/auth';
import { MainDiv } from './styles';

function Main(props) {

    const { sidebarResponsive } = useContext(AuthContext);

    return (
      
    <MainDiv isresponsive={sidebarResponsive}>
        {props.children}

    </MainDiv>    
    
      
    );
  }
  
  export default Main;